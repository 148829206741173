import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, I am',
  name: 'Tushar Vengurlekar',
  subtitle: 'Software Lead Consultant',
  tagline : 'Dream.Believe.Work.Achieve',
  cta: 'Know More',
};

// ABOUT DATA
export const aboutData = {
  img: 'TusharVengurlekar.jpeg',
  paragraphOne: 'A tech specialist with 15 years of experience who can deliver several on-going projects for optimal commercial performance.',
  paragraphTwo:'Actievely working with: React Native, React JS, Node JS, PostgreSQL, Realm, Firebase',
  paragraphThree:'Worked with in Past: Native iOS/Android, MySQL',
  paragraphFour:'Expertise',
  paragraphFive:'• Create and review product requirements \n• Create project plans and roadmaps \n• Guide in setting up the application architecture \n• Build a Team \n• Coordinate with a team of developers for smooth delivery of web and mobile applications. \n• Establish and control the software development process for the organizations  \n• Assess and incorporate latest technologies in the software products'
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'indevelopment.jpeg',
    title: 'Tattoo Design',
    info: 'Techstack : ReactJS, Node JS, PostgreSQL, Redux-Toolkit, Internationalization/Localization',
    info2: 'In Development',
    // url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'indevelopment.jpeg',
    title: 'Medical App - Enterprise',
    info: 'Techstack : React Native, Redux, Realm',
    info2: 'This is an enterprise app. We handle the maintainance and Enhancements',
    // url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'zeal.png',
    title: 'Zeal: Your Always Happy Place',
    info: 'Techstack : React Native, Firebase',
    info2: 'Zeal tracks user moods and relavant details. It also allows to get consulation from the experts',
    url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'indevelopment.jpeg',
  //   title: 'Early Insta',
  //   info: 'Techstack : React JS, Node JS',
  //   info2: 'In Development, Wrapper on Instagram',
  //   url: 'http://159.89.164.75/EarlyInsta/',
  //   // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Fecal Sludge and Septage Management',
    info: 'Techstack : React JS, Node JS, PostgreSQL',
    info2: 'This is a closed enterprise application. This system manages the Fecal Sludge and Septage Management systes for Cities under consideration',
    url: '',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'nddb.png',
    title: 'National Dairy Development Board',
    info: 'Techstack : Angular JS, Node JS, MongoDB',
    info2: 'This is a GIS enterprise application. This system manages data related operation for a  the largest Milk Unition in India',
    url: 'https://gis.nddb.coop/#!/login',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  
];

// Fun PROJECTS DATA
export const funProjectsData = [
  {
    id: nanoid(),
    img: 'indevelopment.jpeg',
    title: 'One C',
    info: 'Techstack : C',
    info2: 'Trying out some old school C functions',
    // url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    repo: 'https://github.com/Tushaarv/OneC', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'indevelopment.jpeg',
    title: 'One Flutter',
    info: 'Techstack : Flutter',
    info2: 'Learning Flutter',
    // url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    repo: 'https://github.com/Tushaarv/OneFlutter', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'indevelopment.jpeg',
    title: 'Yog Time',
    info: 'Techstack : Native Android',
    info2: 'Android App to keep track of Yoga time',
    // url: 'https://play.google.com/store/apps/details?id=expert.zeal.app',
    repo: 'https://github.com/Tushaarv/YogTimer', // if no repo, the button will not show up
  },
];

// gatsby build for production buildd 

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'tushaarv@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tusharvengurlekar/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Tushaarv',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
